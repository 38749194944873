import React, {useEffect, useState} from 'react';
import {IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {base64FromPath} from '@ionic/react-hooks/filesystem';
import {Camera} from '@capacitor/camera';
import {cameraOutline, informationCircleOutline, trashOutline} from 'ionicons/icons';
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import logoutIcon from "../imgs/logout.png"
import TrailerPos1 from "../imgs/trailer_inspect/TrailerPos1.png";
import TrailerPos2 from "../imgs/trailer_inspect/TrailerPos2.png";
import TrailerPos3 from "../imgs/trailer_inspect/TrailerPos3.png";
import TrailerPos4 from "../imgs/trailer_inspect/TrailerPos4.png";
import TrailerPos5 from "../imgs/trailer_inspect/TrailerPos5.png";
import TrailerPos6 from "../imgs/trailer_inspect/TrailerPos6.png";
import TrailerPos7 from "../imgs/trailer_inspect/TrailerPos7.png";
import TrailerPos8 from "../imgs/trailer_inspect/TrailerPos8.png";
import TrailerPos9 from "../imgs/trailer_inspect/TrailerPos9.png";
import TrailerPos10 from "../imgs/trailer_inspect/TrailerPos10.png";
import TrailerPos11 from "../imgs/trailer_inspect/TrailerPos11.png";
import TrailerPos12 from "../imgs/trailer_inspect/TrailerPos12.png";
import TrailerPos13 from "../imgs/trailer_inspect/TrailerPos13.png";
import TrailerPos14 from "../imgs/trailer_inspect/TrailerPos14.png";
import TrailerPos15 from "../imgs/trailer_inspect/TrailerPos15.png";
import TrailerPos16 from "../imgs/trailer_inspect/TrailerPos16.png";
import TrailerPos17 from "../imgs/trailer_inspect/TrailerPos17.png";
import TrailerPos18 from "../imgs/trailer_inspect/TrailerPos18.png";
import TrailerPos19 from "../imgs/trailer_inspect/TrailerPos19.png";
import {ApiService} from "../api/ApiService";
import {config} from '../Constants';
import {toastStore} from "../components/AppToast";
import {PhotoApiService} from "../api/PhotoApiService";
import {PhotoUploadRequest} from "../api/model/PhotoUploadRequest";

const timages: any[] = [];

const firebaseConfig = {
	apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
	authDomain: "western-express-5002c.firebaseapp.com",
	databaseURL: "https://western-express-5002c.firebaseio.com",
	projectId: "western-express-5002c",
	storageBucket: "western-express-5002c.appspot.com",
	messagingSenderId: "799211811572",
	appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
	measurementId: "G-V5MNJV768B"
}
 
const app = initializeApp(firebaseConfig)
 
const handleEventLog = (eventType: string, info: string) => {
	const analytics = getAnalytics(app);
	logEvent(analytics , eventType, {eventType: info})
	console.log(eventType);
}

var c = document.getElementById("canv");
// var $ = c!.getContext("2d");

let photoList = [
  {
    title: "Left Front of Trailer",
    pic: TrailerPos1,
    questions: [
      {
        questionTitle: "Gladhands original?",
        answer: "Yes",
        options: ["Yes", "No"]
      },
      {
        questionTitle: "DOT up to date?",
        answer: "Yes",
        options: ["Yes", "No"]
      },
      {
        questionTitle : "Issue?",
        answer: "None",
        options: ["None", "Bent", "Break", "Crack", "Missing", "Twisted"]
      }
    ]
  },{
      title: "Driver Side of Trailer",
      pic: TrailerPos2,
      questions: [
      {
        questionTitle: "Issue?",
        answer: "None",
        options: ["None", "Bent", "Break", "Missing", "Crack", "Twisted"]
      }
    ]
  },{
    title: "Left Landing Gear",
    pic: TrailerPos3,
    questions: [
    {
      questionTitle: "Is it straight?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Brackets secure?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Greased?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Bent", "Break", "Crack", "Twisted"]
    }
  ]
},{
  title: "Left Front Axle Tire Tread",
  pic: TrailerPos4,
  questions: [
    {
    questionTitle: "Left Front Outer 32nds",
    answer: ""
  },
  {
    questionTitle: "Left Front Inner 32nds",
    answer: ""
  },
  {
    questionTitle : "Issue?",
    answer: "None",
    options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
  }
]
},{
  title: "Left Front Axle Tire Sidewall",
  pic: TrailerPos5,
  questions: [
    {
    questionTitle: "Left Front Outer Make",
    answer: ""
  },
  {
    questionTitle: "Left Front Inner Make",
    answer: ""
  },
  {
    questionTitle : "Issue?",
    answer: "None",
    options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Improperly adjusted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
  }
]
},{
  title: "Left Rear Axle Tire Tread",
  pic: TrailerPos6,
  questions: [
    {
      questionTitle: "Left Rear Outer 32nds",
      answer: ""
    },
    {
      questionTitle: "Left Rear Inner 32nds",
      answer: ""
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
    }
]
},{
  title: "Left Rear Axle Tire Sidewall",
  pic: TrailerPos7,
  questions: [
    {
      questionTitle: "Left Rear Outer Make",
      answer: ""
    },
    {
      questionTitle: "Left Rear Inner Make",
      answer: ""
    },
    {
      questionTitle: "Mud Flap Intact?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Improperly adjusted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
    }
  ]
  },{
    title: "Left Rear Corner of Trailer",
    pic: TrailerPos17,
    questions: [
      {
        questionTitle: "Issue?",
        answer: "None",
        options: ["None", "Bent", "Broken", "Crack", "Has a hole", "Missing", "Twisted"]
      }
  ]
  },{
    title: "Rear of Trailer",
    pic: TrailerPos8,
    questions: [
      {
      questionTitle: "Tag Light Working?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Tag Present",
      answer: "Yes",
      options: ["Yes", "No"]
    },{
      questionTitle: "Issue?",
      answer: "None",
      options: ["None", "Bent", "Broken", "Burnt out", "Flickers", "Crack", "Cut", "Inoperative", "Has a hole", "Missing", "Twisted", "Worn"]
    }
  ]
  },{
    title: "Right Rear Corner of Trailer",
    pic: TrailerPos16,
    questions: [
      {
        questionTitle: "Issue?",
        answer: "None",
        options: ["None", "Bent", "Broken", "Crack", "Has a hole", "Missing", "Twisted"]
      }
  ]
  },{
    title: "Right Rear Axle Tire Tread",
    pic: TrailerPos11,
    questions: [
      {
      questionTitle: "Right Rear Outer 32nds",
      answer: ""
    },
    {
      questionTitle: "Right Rear Inner 32nds",
      answer: ""
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
    }
  ]
  },{
    title: "Right Rear Axle Tire Sidewall",
    pic: TrailerPos12,
    questions: [
      {
      questionTitle: "Right Rear Outer Make",
      answer: ""
    },
    {
      questionTitle: "Right Rear Inner Make",
      answer: ""
    },
    {
      questionTitle: "Mud Flap Intact?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Improperly adjusted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
    }
  ]
  },{
    title: "Right Front Axle Tire Tread",
    pic: TrailerPos9,
    questions: [
      {
      questionTitle: "Right Front Outer 32nds",
      answer: ""
    },
    {
      questionTitle: "Right Front Inner 32nds",
      answer: ""
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
    }
  ]
  },{
    title: "Right Front Axle Tire Sidewall",
    pic: TrailerPos10,
    questions: [
      {
      questionTitle: "Right Front Outer Make",
      answer: ""
    },
    {
      questionTitle: "Right Front Inner Make",
      answer: ""
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Dragged", "Flat", "Wear", "Leaking", "Twisted", "Improperly adjusted", "Won't Inflate", "Out of Round", "Run Flat", "Mismatched", "Tread Lift", "Low PSI"]
    }
  ]
  },{
    title: "Right Landing Gear",
    pic: TrailerPos13,
    questions: [
      {
      questionTitle: "Is it straight?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Brackets secure?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Greased?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Bent", "Break", "Crack", "Twisted"]
    }
  ]
  },{
    title: "Passenger Side of Trailer",
    pic: TrailerPos14,
    questions: [
      {
        questionTitle: "Issue?",
        answer: "None",
        options: ["None", "Bent", "Break", "Missing", "Crack", "Twisted"]
      }
  ]
  },{
    title: "Right Front of Trailer",
    pic: TrailerPos15,
    questions: [
      {
      questionTitle: "Damage to Header?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Bent", "Break", "Crack", "Missing", "Twisted"]
    }
  ]
  },{
    title: "Left Front Brakes and Slack Adjuster",
    pic: TrailerPos18,
    questions: [
      {
      questionTitle: "Is the Anchor Bolt secured and centered?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Is the Celvis Pin attached to the Slack?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Greased?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Binding", "Broken", "Cracked", "Grinding", "Improperly adjusted", "Inoperative", "Locked up", "Missing", "Worn out"]
    }
  ]
  },{
    title: "Left Rear Brakes and Slack Adjuster",
    pic: TrailerPos19,
    questions: [
     {
      questionTitle: "Is the Anchor Bolt secured and centered?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Is the Celvis Pin attached to the Slack?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Greased?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Binding", "Broken", "Cracked", "Grinding", "Improperly adjusted", "Inoperative", "Locked up", "Missing", "Worn out"]
    }
  ]
  },{
    title: "Right Front Brakes and Slack Adjuster",
    pic: TrailerPos18,
    questions: [
    {
      questionTitle: "Is the Anchor Bolt secured and centered?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Is the Celvis Pin attached to the Slack?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Greased?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Binding", "Broken", "Cracked", "Grinding", "Improperly adjusted", "Inoperative", "Locked up", "Missing", "Worn out"]
    }
  ]
  },{
    title: "Right Rear Brakes and Slack Adjuster",
    pic: TrailerPos19,
    questions: [
      {
      questionTitle: "Is the Anchor Bolt secured and centered?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Is the Celvis Pin attached to the Slack?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle: "Greased?",
      answer: "Yes",
      options: ["Yes", "No"]
    },
    {
      questionTitle : "Issue?",
      answer: "None",
      options: ["None", "Binding", "Broken", "Cracked", "Grinding", "Improperly adjusted", "Inoperative", "Locked up", "Missing"]
    }
  ]
  }

]

const BorderInspections: React.FC<{moduleHistory: string}> = ({ moduleHistory }) => {

  const [loading, setLoading] = useState(false);
  const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
  const [images, setImages] = useState<PhotoUploadRequest[]>([]);
  const [inspectType, setInspectType] = useState("southbound");
  const [techCode, setTechCode] = useState("");
  const [trailerNumber, setTrailerNumber] = useState("");
  const [hidden, setHidden] = useState(true);
  const [showEdit, setShowEdit] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
  }, [images]);

  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  }

  function checkforSouthReport() {
    ApiService.laredoInspectionGet("borderInspections", {trailer: trailerNumber, reportType: inspectType})
    .then(it => {
      console.log("Southern Reports", it);
    })
  }

  function sendEmail() {
    const emailBody = `An inspection report has been filed for trailer: ${trailerNumber}  by ${localStorage.getItem("username")}. All photos can be found via the following link: `;
    ApiService.sendEmail("borderInspections", {to: ["laredoInspection"], subject: `Laredo Inspection: Trailer ${trailerNumber}`, body: emailBody}).then(() => window.location.reload());
  }

  const b64toBlob = (base64:any) => 
  fetch(base64).then(res => res.blob())

  async function submitReport() {
    if (images.length < 21) {
      toastStore.getState().setToast("Please ensure you have the required number of photos for a submission (21) and that all 32nd fields are filled out");
      return;
    }

    setLoading(true);
    let inspectID = "";
    ApiService.laredoInspectionSubmit("borderInspections", {trailer: trailerNumber, techCode: techCode, reportType: inspectType, reportData: JSON.stringify(photoList)}).then(it => {
      inspectID = it.inspectionId.toString()
      ApiService.photoSubmission("inspect", {
        type: 'inspect',
        refId: it.inspectionId.toString(),
        dmCode: "",
        division: "",
        unit: "",
        trailer: "",
        load: ""
      })
      })
      .then(() => {
      PhotoApiService.uploadPhotos(images, inspectID, 'inspect')
          .then(() => {
            toastStore.getState().setSuccessToast('Your photos were submitted.');
            sendEmail();
          })
          .catch(() => toastStore.getState().setToast('Error! One or more photos could not be uploaded, check your connection and please try again.'));
    }).finally(() => setLoading(false));

  }

    async function takePicture() {

      if((images.length === 3 || images.length === 5 || images.length === 10 || images.length === 12) && !photoList[images.length].questions.every(el => el.answer)) {
        toastStore.getState().setToast('Please answer all questions.');
      } else {
        const image = await Camera.getPhoto({
          quality: 30,
          allowEditing: false,
          resultType: CameraResultType.Uri
        });
        const fileName = new Date().getTime() + '.jpeg';
        // await addDateTime(image.webPath!)
        const base64Data = await base64FromPath(image.webPath!);
        const newPhotos = [...images, {
          filepath: fileName,
          webviewPath: image.webPath,
          data: base64Data
        }];
        setImages(newPhotos)
        timages.push(image)
        if (timages.length > 0) {
          setPhotoMsg("Take another photo")
        }
      }
    }

    const removePhoto = (e: any) => {
      const name = e.filepath;
      let tmp = images
      tmp = tmp.filter(image => image.filepath !== name)
      setImages(tmp);
    }

    function handleEdit (index: number) {

      if(!showEdit) {
        setSelectedIndex(index)
        setShowEdit(true)
      }
    }
    async function replacePhotoAt (index: number) {
       const image = await Camera.getPhoto({
          quality: 30,
          allowEditing: false,
          resultType: CameraResultType.Uri
        });
        const fileName = new Date().getTime() + '.jpeg';
        // await addDateTime(image.webPath!)
        const base64Data = await base64FromPath(image.webPath!);
        const newPhoto = {
          filepath: fileName,
          webviewPath: image.webPath,
          data: base64Data
        };
        let tmp = images
        tmp[index] = newPhoto
        setImages([...images])
    }

    function handlePrev () {
      let tmp = selectedIndex
      if(tmp > 0) {
        tmp--
        setSelectedIndex(tmp)
      }
    }
     function handleNext () {
       let tmp = selectedIndex
       console.log('tmp', tmp)
       console.log('images', images.length)
      if(tmp < images.length - 1) {
        tmp++
        setSelectedIndex(tmp)
      } else if (tmp === images.length - 1) {
        setShowEdit(false)
      }
    }

    function handleInspectCheck(type: any) {
      if (trailerNumber !== "") {
        setInspectType(type)
        if (type === 'northbound') {
          checkforSouthReport();
        }
      } else {
        toastStore.getState().setToast("Please enter the trailer number first");
      }
    }

  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
          <IonTitle>TLC Photos</IonTitle>
          <IonButtons slot="end">
              <a>
                <img style={{height: "28px", marginRight: "15px", color: "white"}} onClick={() => ApiService.userLogout("borderInspections")} src={logoutIcon} alt="logout" />
              </a>
          </IonButtons>
        </IonToolbar> 
      </IonHeader>
       <IonModal isOpen={loading} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                          <p>Please wait... your photos are being submitted.</p>
                          <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div> 
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
      <IonContent color='white'>
        <IonCard color="#fff">
          <IonCardContent style={{background: "#fff"}}>
          <div className='main'>
            <div>
            <IonItem color="white" lines="none" style={{}}>
              <div style={{margin: "auto"}}>
                <h1 style={{fontSize: "28px"}}>TLC Inspection</h1>
                  <IonItem lines="none" color="transparent">
                  <div className='item-container' id="sign-up" style={{margin: "0px"}}>
                    <IonLabel id="black" style={{"padding": "0px 5px 0px 8px"}} position="floating"><b>Tech Code:</b></IonLabel>
                    <IonInput id="input-text" value={techCode} onIonChange={e => setTechCode(e.detail.value!)}></IonInput>
                  </div>
                </IonItem>
                {/* <IonItem lines="none" color="transparent">
                  <div className='item-container' id="sign-up" style={{margin: "0px"}}>
                    <IonLabel id="black" style={{"padding": "0px 5px 0px 8px"}} position="floating"><b>Unit #:</b></IonLabel>
                    <IonInput type="email" id="input-text" value={unitNumber} onIonChange={e => setUnitNumber(e.detail.value!)}></IonInput>
                  </div>
                </IonItem> */}
                <IonItem lines="none" color="transparent">
                  <div className='item-container' id="sign-up" style={{margin: "0px"}}>
                    <IonLabel id="black" style={{"padding": "0px 5px 0px 8px"}} position="floating"><b>Trailer #:</b></IonLabel>
                    <IonInput type="email" id="input-text" value={trailerNumber} onIonChange={e => setTrailerNumber(e.detail.value!)}></IonInput>
                  </div>
                </IonItem>
                <IonItem lines="none" color="transparent">
                  <div className="item-container" style={{display: "grid", margin: "0px"}}>
                      <p style={{ color: "#000", padding: '5px', textAlign: "center"}}><b>Report Type:</b></p>
                      <div className="input-container" style={{margin: "0"}}>
                          <select id="reportType" className="input" placeholder=" " value={inspectType} onChange={e => handleInspectCheck(e.target.value)} style={{ height: "20px", textAlign: "center", backgroundColor: "#f0f0f0"}}>
                              <option value={"northbound"}>Northbound</option>
                              <option value={"southbound"}>Southbound</option>
                          </select>
                      </div>
                  </div>
                </IonItem>
                <div className="divider-divider"></div>
              </div>
            </IonItem>
            {showEdit ? <div style={{ display: "flex", textAlign: 'center'}}>
                <p style={{ color: "#5096FF", textAlign: "center", fontSize: "x-large", marginBottom: "16px", margin: 'auto'}} onClick={() => handlePrev()}>Previous</p>
                  <div className='item-container' id="sign-up" style={{margin: "auto", width: "250px"}}><IonItem lines="none" color="light" style={{borderRadius: "10px", marginBottom: "10px"}} onClick={() => setHidden(!hidden)}>
                    <div>
                      <p style={{margin: 0, display: "flex", gap: "4px", alignItems: "center", fontSize: "14px"}} className="subtitle">{selectedIndex+1}. {photoList[selectedIndex].title}<IonIcon icon={informationCircleOutline}></IonIcon></p>
                      <div className='item-container' style={{ display: "grid", alignItems: "center", backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px", marginBottom: "25px", marginLeft: "auto", marginRight: "auto"}}>
                      <img alt="example image" src={photoList[selectedIndex].pic} />
                      </div>
                      <div className="item-container" style={{display: "grid", margin: "0px"}}>
                          <div className="item-container" style={{display: "grid", margin: "0px"}}>
                            {photoList[selectedIndex].questions.map(function(questionArray) {
                              return <>
                                <p style={{ color: "black", padding: '5px', textAlign: "center"}}><b>{questionArray.questionTitle}</b></p>
                                  {Object.keys(questionArray).indexOf("options") !== -1 ?
                                  <select  value={questionArray.answer} id="accident" className="input" placeholder=" " onChange={e => questionArray.answer = e.target.value!} style={{ height: "20px", textAlign: "center", backgroundColor: "#fff", marginBottom: "15px"}}>
                                    {questionArray.options!.map(function(answerOpts) {
                                      return <option value={answerOpts}>{answerOpts}</option>
                                    })}
                                    </select> :
                                    <IonInput className='input' style={{marginBottom: "15px", paddingLeft: "5px"}} onIonChange={e => {questionArray.answer = e.detail.value!}}></IonInput>
                                  }
                              </>
                            })}
                          </div>
                      </div>
                    </div>
                  </IonItem></div>
                <p style={{ color: "#5096FF", textAlign: "center", fontSize: "x-large", marginBottom: "16px", margin: 'auto'}} onClick={() => handleNext()}>Next</p>
              </div> :
            <IonItem lines="none" color="transparent">
              <div className='item-container' id="sign-up" style={{margin: "auto", width: "250px"}}>
                <p style={{fontSize: "medium", textAlign: "center"}}><b>Submit photos in order of prompt and fill out required fields:</b></p><br/>
                {photoList.map(function(element, index) {
                    return ( images.length === photoList.indexOf(element) ? 
                    <IonItem lines="none" color="light" style={{borderRadius: "10px", marginBottom: "10px"}} onClick={() => setHidden(!hidden)}>
                      <div>
                        <p style={{margin: 0, display: "flex", gap: "4px", alignItems: "center", fontSize: "14px"}} className="subtitle">{index+1}. {element.title}<IonIcon icon={informationCircleOutline}></IonIcon></p>
                        <div className='item-container' style={{ display: "grid", alignItems: "center", backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px", marginBottom: "25px", marginLeft: "auto", marginRight: "auto"}}>
                        <img alt="example image" src={element.pic} />
                        </div>
                        <div className="item-container" style={{display: "grid", margin: "0px"}}>
                            <div className="item-container" style={{display: "grid", margin: "0px"}}>
                              {element.questions.map(function(questionArray) { 
                                return <>
                                  <p style={{ color: "black", padding: '5px', textAlign: "center"}}><b>{questionArray.questionTitle}</b></p>
                                    {Object.keys(questionArray).indexOf("options") !== -1 ? 
                                    <select id="accident" className="input" placeholder=" " onChange={e => questionArray.answer = e.target.value!} style={{ height: "20px", textAlign: "center", backgroundColor: "#fff", marginBottom: "15px"}}>
                                      {questionArray.options!.map(function(answerOpts) {
                                        return <option value={answerOpts}>{answerOpts}</option>
                                      })}
                                      </select> :
                                      <IonInput className='input' style={{marginBottom: "15px", paddingLeft: "5px"}} onIonChange={e => {questionArray.answer = e.detail.value!}}></IonInput>
                                    }
                               </>
                              })}
                            </div>
                        </div>
                      </div>
                    </IonItem> : null )
                })}
              </div>
            </IonItem> }
          {showEdit ? <button className='button' style={{margin: "25px auto 25px auto"}} onClick={() => {replacePhotoAt(selectedIndex)}}><p>Edit Photo</p><IonIcon icon={cameraOutline}></IonIcon></button> : <button className='button' style={{margin: "25px auto 25px auto"}} onClick={e => {takePicture(); handleEventLog("tarp_pay", "submit-Photo")}}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>}
          
            </div>
              { images.length !== 0 ?
            <div style={{textAlign: "center"}}>
              <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', padding: "8px", backgroundColor: "#f4f5f8", margin: "auto"}} >
                {images.map(function(img, index) {
                  return <div key={index} style={{position: "relative", margin: "10px"}} >
                    <span style={{display: "flex", fontWeight: "600"}}>{index + 1}.</span>
                    <img alt="tarppay img"  style={{width: "100px", borderRadius: "10px"}}src={img.webviewPath} onClick={()=> {handleEdit(index)}}/>
                    { images.length - 1 === index ? <button style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "15px", left: "5px"}} onClick={e => {removePhoto(img)}}><IonIcon style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button> : null }
                  </div>
                })}
              </div>
            </div> : null } 
            {/* <IonItem lines="none" color="transparent">
              <div className='item-container' style={{margin: "auto", width: "250px"}}>
                <div className="input-container">
                    <textarea id="comment-box" className="input-2"  placeholder=" " value={comments} onChange={e => setComments(e.target.value!)}/>
                    { comments === "" ? <p className="placeholder">Comments</p> : null }
                </div>
              </div>
            </IonItem> */}
          </div>
            <IonItem lines="none" color="white">
              <div style={{textAlign: "center", margin: "auto"}}>
                <button className="western-button submit" style={{width: "35vw"}} onClick={e => {submitReport(); handleEventLog("laredo_inspect", "submit-laredo")}}>SUBMIT</button>
              </div>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
 
    </IonPage>
  );
};

export default BorderInspections;
