import React, {useState} from "react";
import {IonItem, IonItemGroup, IonModal} from "@ionic/react";
import {userStore} from "../../api/AuthService";
import {version as appVersion} from '../../Constants';

const AppBroadcast = (props: any) => {

    const [modalOpen, setModalOpen] = useState(true);

    const accept = () => {
        props.onAccept();
        setModalOpen(false);
    }

    return <IonModal isOpen={modalOpen} backdropDismiss={false}>
        <IonItemGroup>
            <IonItem style={{"--background": "#212121"}}>
                <div className="item-container">
                    <h1 style={{color: "#fff"}}>{props.message.header}</h1>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div style={{minHeight: '462px'}}>
                    <p style={{whiteSpace: "pre-line"}}>{props.message.message}</p>
                    <br/>
                    <iframe style={{marginTop: "10px", height: "30vh", borderRadius: "5px"}} src={"https://www.youtube.com/embed/" + props.message.url}></iframe>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div className="item-container">
                    <button className="app-btn app-btn-primary w-100 text-center d-block" onClick={() => accept()}>Done</button>
                </div>
            </IonItem>
        </IonItemGroup>
    </IonModal>
}
export default AppBroadcast;
