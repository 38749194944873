import React, {useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonModal,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import newIcon from "../imgs/new-trainee.png"
import {chevronForward, warningOutline} from 'ionicons/icons';
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {Trainee} from "../api/model/Trainee";

const ReleaseTrainee: React.FC = () => {

    const today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)

    const [showModal, setShowModal] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [showPassed, setShowPassed] = useState(false);
    const [noTrainees, setNoTrainees] = useState(false);
    const [Agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasTrainee, setHasTrainee] = useState(false);
    const [days, setDays] = useState<number>(0);
    const [failureReason, setFailureReason] = useState('');
    const [submitType, setSubmitType] = useState("pass");
    const [trainees, setTrainees] = useState<Trainee[]>([]);
    const [trainee, setTrainee] = useState<Trainee>({
      currentCourseDay: 0,
      traineeCode: '',
      traineeName: '',
      currentCourseId: 0,
      dateCreated: '',
      drivingHours: 0,
      dmCode: '',
      passed: 0,
      trainerCode: '',
      trainerName: '',
      released: 0,
      seatedDate: ''
    });

    let currentDate = today.toISOString().split('T')[0]

    useEffect(() => {
        ApiService.getTrainees("releaseTrainee", {released: 0}).then(it => {
            setNoTrainees(!it.length);
            setTrainees(it);
        });
    }, []);

    function getTraineeProfile(traineeCode: string) {
        ApiService.getTraineeProfile("releaseTrainee", traineeCode).then(it => {
            setTrainee(it);
            setDays(it.currentCourseDay);
            setHasTrainee(true);
        });
    }

    const handleSubmit = () => {
        if (Agreed) {
            releaseTrainee();
        } else {
            toastStore.getState().setToast('Please select the checkbox for the agreement');
        }
    }

    function releaseTrainee() {
        setLoading(true);
        ApiService.traineeRelease("releaseTrainee", {
            traineeCode: trainee.traineeCode,
            releasedDate: currentDate,
            trainerCode: trainee.trainerCode,
            currentCourseDay: trainee.currentCourseDay,
            passed: submitType === "fail" ? 0 : 1,
            failureReason: submitType === "fail" ? failureReason : ""
        }).then(() => {
            setShowModal(true);
        }).finally(() => {
            setLoading(false);
        });
    }

  return (
    <IonPage>
    <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home" text=""/>
          </IonButtons>
          <IonTitle>Release Trainee</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
      { hasTrainee ?
      <IonButton style={{padding: "0px"}} id="western-button" expand="block" onClick={e => setHasTrainee(false)}>Trainees</IonButton> :
      null }
        <IonModal isOpen={showModal} cssClass='my-custom-class'>
          <IonItem lines="none" color="light">
            <div className="item-container">
              <p className='subtitle'>Thank You</p>
              <p>Your release has been submitted</p>
              <IonButton id="western-button-login" className='button-2' expand="block" href="/Home" onClick={ function() { setShowModal(false); setShowPassed(false); setShowFailed(false)}}>Done</IonButton>
            </div>
          </IonItem>    
        </IonModal>

        <IonModal isOpen={loading} id="transparent">
          <div className="modal-div">
              <div id="modal">
                  <div className='loading-div'>
                    <p>Please wait... your photos are being submitted.</p>
                    <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                      <div className="stage">
                        <div className="dot-flashing"></div>
                      </div> 
                    </div>
                  </div>
              </div>
          </div>
        </IonModal>

        {hasTrainee ? 
        <IonList style={{ padding: 0}}>
          <IonItem lines="none" color="light">
            <div className="item-container">
              <p>Today's Date {currentDate}</p>
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
            <IonSegment value={submitType} style={{borderRadius: "8px"}} onIonChange={e => setSubmitType(e.detail.value!)}>
              <IonSegmentButton value="pass" className={submitType === "pass" ? "western-segment-button-green" : "western-segment-button" } >Pass</IonSegmentButton>
              <IonSegmentButton value="fail" className={submitType === "fail" ? "western-segment-button-gray" : "western-segment-button" }>Fail</IonSegmentButton>
            </IonSegment>
          </IonItem>
          <IonItem lines="none" color="light">
            <div className="item-container">
              <div className="input-container">
                  <input id="days" className="input" type="number" placeholder=" " value={days} onChange={e => setDays(parseInt(e.target.value!))}/>
                  <p className="placeholder">Days Trained</p>
              </div>
              <IonItem color="light">
                <div>
                  {submitType === "pass" ?
                  <IonText><p className="release-agreement">As a duly appointed driver trainer for Western Express, Inc., I do hereby release my student for final written and driving tests. To the best of my knowledge my student has completed training. I have completed all of my required responsibilities in the training of my student and it is my firm belief that he or she is able to perform all tasks associated with the safe and successful operations of a commercial motor vehicle. I believe my student will be an asset to the company and perform their job related duties in a safe, courteous, and responsible manner.</p></IonText> :
                  <IonText><p className="release-agreement">As a duly appointed driver trainer for Western Express, Inc., I DO NOT feel that my Trainee is capable of performing his job related duties in a safe and professional manner. Despite my best efforts I have been unable to instill or observe the necessary skills or work ethics required to succeed safely and professionally as an driver for Western Express. I have detailed their shortcomings in the training manual and below and they are aware of my comments and decision to recommend a company evaluation of their skills without my granting a release from training. I have found the following general problems with their overall performance.</p></IonText>
                  }
                </div>
              </IonItem>
              <IonItem lines="none" color="light">
                <div style={{display: "flex", margin: "auto"}}>
                  <span style={{fontSize:"16px"}}><p>I have read and agree<br />to the above statement</p></span>
                  <IonCheckbox style={{marginLeft: "15px", height: "24px", width: "24px"}} checked={Agreed} onClick={ function() {setAgreed(!Agreed)}}></IonCheckbox>
                </div>
              </IonItem>
              <IonButton id="western-button-submit" expand="block" onClick={e => {handleSubmit()}}>Submit</IonButton>
              {/* <IonButton id="western-button-submit" expand="block" onClick={e => {setPassed(true);setShowPassed(true)}}>Release Student</IonButton>
              <IonButton id="western-button" style={{padding: "0px"}} expand="block" onClick={e => {setPassed(false);setShowFailed(true)}}>Fail Student</IonButton> */}
            </div>
          </IonItem>
        </IonList> 
        :
      <> { !noTrainees ?
      <IonList className="mb-20" style={{padding: "0px"}}>
        <IonItem lines="none" color='light'>
          <div className='item-container'>
            <p className="title">Select Trainee Profile</p>
            <p className='subtitle'>Found {trainees.length} trainees.</p>
          </div>
        </IonItem>
      {trainees.map(function(trainee) {
         const profile = trainee.traineeCode;
         return <IonItem key={trainee.traineeCode} onClick={() => {getTraineeProfile(trainee.traineeCode)}} lines="none" color="light">
          <div className='item-container' style={{ backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px"}}>
            <div className="space-between">
              <div style={{ width: "80%"}}>
                <p style={{ fontSize:"medium", margin: "4px"}}>{trainee.traineeCode}</p>
                <p style={{ fontSize: "small", margin: "4px"}}>Status: {trainee.released !== null ? "Released on: " + trainee.released : "In Training"}</p>
              </div>
              <IonIcon icon={chevronForward} style={{ fontSize: "large"}}></IonIcon>
            </div>
          </div>

        </IonItem>
      })}
        </IonList>
      :
      <IonItem color="light" lines="none">
        <div className='item-container' style={{ backgroundColor: "#ffffff", padding: "8px", borderRadius: "10px"}}>
        <p className='subtitle' style={{ color: "#D0A024", display: "flex", alignItems: "center", gap: "4px"}}><IonIcon icon={warningOutline}></IonIcon> Attention</p>
        <p className='title'>You don't have any trainees to release</p>
        <Link to="/newtrainee" className="western-button-link">
                    <div className="green">
                        <img src={newIcon} alt="orientation" className="link-icon"/><p>New Trainee</p>
                    </div>
                </Link>
        </div>

      </IonItem> } </>
      }
      </IonContent>
    </IonPage>
  );
};

export default ReleaseTrainee;
