import React, {useEffect, useState} from "react";
import {IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {ApiService} from "../api/ApiService";
import {userStore} from "../api/AuthService";
import {Trainee} from "../api/model/Trainee";
import {useHistory} from "react-router-dom";
import placeholder from "../imgs/placeholder_profile.png";
import {TrainingCourse} from "../api/model/TrainingCourses";
import {TrainingPrompt} from "../api/model/TrainingPrompt";

const TraineePersonalProfile: React.FC = () => {

    const history = useHistory();
    const [trainee, setTrainee] = useState<Trainee>({
        currentCourseDay: 0,
        traineeCode: '',
        traineeName: '',
        currentCourseId: 0,
        dateCreated: '',
        drivingHours: 0,
        dmCode: '',
        passed: 0,
        trainerCode: '',
        trainerName: '',
        released: 0,
        seatedDate: ''
      });
    const [course, setCourse] = useState<TrainingCourse>();
    const [prompts, setPrompts] = useState<TrainingPrompt>();

    useEffect(() => {
        ApiService.getTraineeProfile("traineePersonalProfile", userStore.getState().driver.driverCode).then(it => {
            if (it.currentCourseId) {
                Promise.all([
                    ApiService.getTrainingCourse("traineePersonalProfile", {id: it.currentCourseId}).then(it => setCourse(it)),
                    ApiService.getTrainingPrompts("traineePersonalProfile", {currentCourseId: it.currentCourseId, currentCourseDay: it.currentCourseDay}).then(it => setPrompts(it))
                ]).finally(() => {setTrainee(it); console.log("it", it);});
            } else {
                setTrainee(it);
                console.log("it", it);
                
            }
        }).catch(() => {
            history.push('/');
        });
    }, []);

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/Home"/>
                </IonButtons>
                <IonTitle>Trainee Profile</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
            {trainee && <>
                <IonCard color="light">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 d-flex border-top border-2 flex-row-reverse flex-lg-row ps-4">
                            <img className="profile-photo profile-photo-sm mt-4 me-4" onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeholder
                            }} src={userStore.getState().profilePictureUrl} alt="avatar"/>
                            <div className="w-100">
                                <h1>Trainee Info</h1>
                                <p><b>Code:</b> {trainee.traineeCode}</p>
                                <p><b>Name:</b> {trainee.traineeName}</p>
                                {course
                                    ? <>
                                        <p><b>Course:</b> {course.title}</p>
                                        <p><b>Hours Completed:</b> {trainee.drivingHours}</p>
                                    </>
                                    : <p><b>Course:</b> <span className="color-danger">No course assigned.</span></p>
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 border-start border-top border-2 ps-4">
                            <h1>Trainer Info</h1>
                            <p><b>Code:</b> {trainee.trainerCode}</p>
                            <p><b>Name:</b> {trainee.trainerName}</p>
                        </div>
                    </div>
                </IonCard>
                {prompts &&
                    <IonCard color="light">
                        <div className="col-12 border-start border-top border-2 px-4">
                            <h2>Activity Prompt</h2>
                            <p>{prompts?.activity}</p>
                            <h2>Safety Prompt</h2>
                            <p>{prompts?.safety}</p>
                        </div>
                    </IonCard>
                }
            </>}
        </IonContent>
    </IonPage>
}

export default TraineePersonalProfile;
