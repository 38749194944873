import React, {useEffect, useState} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {Trainee} from "../api/model/Trainee";
import {TrainingReportBackingInfo} from "../api/model/TrainingReportBacking";
import {TrainingReport} from "../api/model/TrainingReports";


const BackingEvents: React.FC = () => {
  
  const [selectedTrainee, setSelectedTrainee] = useState('');
  const [selectedScore, setSelectedScore] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [hasReports, setHasReports] = useState(false);
  const [noTrainees, setNoTrainees] = useState(false);
  const [traineeCode, setTraineeCode] = useState('');
  const [report, setReport] = useState<TrainingReportBackingInfo>({
    trainerCode: '',
    backingEvent: 0,
    backingTime: 0,
    traineeDrove: 0,
    backingGrade: '',
    backingTries: '',
    drivingSummary: '',
    proComments: '',
    roadTest: '',
    conComments: '',
    courseId: 0,
    courseDay: 0,
    overallScore: '',
    weather: '',
    roadStyle: '',
    dmCode: '',
    hoursDriven: 0,
    milesDriven: 0
  })
  const [reports, setReports] = useState<TrainingReport[]>([]);
  const [trainees, setTrainees] = useState<Trainee[]>([]);
  const [trainee, setTrainee] = useState<Trainee>({
    currentCourseDay: 0,
    traineeCode: '',
    traineeName: '',
    currentCourseId: 0,
    dateCreated: '',
    drivingHours: 0,
    dmCode: '',
    passed: 0,
    trainerCode: '',
    trainerName: '',
    released: 0,
    seatedDate: ''
  });

  useEffect(() => {
    ApiService.getTrainees("backingEvents",{}).then(it => {
      setNoTrainees(!it.length);
      setTrainees(it);
    });
  }, []);

  function getTraineeReports(traineeCode: string) {
    ApiService.getTrainingReports("backingEvents",{backingEvents: 'true', traineeCode}).then(it => {
      parseReports(it.reports);
      setHasReports(!!it.reports.length);
    });
  }

  function parseReports(reportsArray: any[]) {
    let tempArray: any[] = []
    reportsArray.forEach(element => {
      let obj: TrainingReport = {date: '', trainerCode: ''};
      obj.date = element.date;
      obj.trainerCode = element.trainerCode
      tempArray.push(obj);
    });
    setReports(tempArray);
  }

  function getBackingDetails(timeSubmitted: string) {
    ApiService.getTrainingReportBacking("backingEvents",{traineeCode, timeSubmitted}).then(it => {
      setReport(it.report);
      setShowModal(true);
    });
  }

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home" />
          </IonButtons>
          <IonTitle>Backing Events</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      { hasReports ?
      <IonButton id="western-button" onClick={e => setHasReports(false)}>Trainees</IonButton> :
      null }
        <IonModal isOpen={showModal} cssClass='my-custom-class'>
          <IonCard>
            <IonList>
              <IonItem>
                <IonLabel>Trainee</IonLabel>
                <IonNote slot="end" color="black">{traineeCode}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Trainer Code</IonLabel>
                <IonNote slot="end" color="black">{report.trainerCode}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>DM Code</IonLabel>
                <IonNote slot="end" color="black">{report.dmCode} ANGRY</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Backing Grade</IonLabel>
                <IonNote slot="end" color="black">{report.backingGrade}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Backing Tries</IonLabel>
                <IonNote slot="end" color="black">{report.backingTries}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Time Backing</IonLabel>
                <IonNote slot="end" color="black">{report.backingTime}</IonNote>
              </IonItem>
            </IonList>
          </IonCard>    
          <IonButton  className="mb-20" id="western-button" expand="block" onClick={ function() { setShowModal(false) }}>Done</IonButton>
        </IonModal>
        { hasReports ? <>
        <IonList className="mb-20">
        {reports.map(function(dayreport) {
          return <div style={{width: "100%"}}>
            <IonItem onClick={ function() { getBackingDetails(dayreport.date) }}>
              <IonLabel>Course Day</IonLabel>
              <IonNote slot="end" color="black">{dayreport.date}</IonNote>
            </IonItem>
          </div>
          })}
        </IonList> 
          </> :
        <> { !noTrainees ?
          <IonList className="mb-20">
             {trainees.map(function(trainee) {
                 return  <IonItem key={trainee.traineeCode} onClick={() => {setTraineeCode(trainee.traineeCode); getTraineeReports(trainee.traineeCode)}}>
                 <IonLabel>
                   <h2>Trainee: {trainee.traineeCode}</h2>
                   <h3>Course Id: {trainee.currentCourseId}</h3>
                   <h4>Course Day: {trainee.currentCourseDay}</h4>
                 </IonLabel>
                 </IonItem>
             })}
  
           </IonList>
         : 
         <IonCard>
         <IonCardHeader>
             <IonCardSubtitle>Attention!</IonCardSubtitle>
             <IonCardTitle>You Have No Trainees</IonCardTitle>
         </IonCardHeader>
             <IonCardContent>
               <h3 style={{fontSize: "17px", marginBottom: '20px'}}>Please go to <span style={{color: "#A9EF01"}}>New Trainee</span> to claim a trainee for training.</h3>
               <IonButton id="western-button" expand="block" href="/Training/newtrainee"><div id="btn-text">New Trainee</div></IonButton>
             </IonCardContent>  
         </IonCard> } </>
       }

      </IonContent>
    </IonPage>
  );
};

export default BackingEvents;
